import { Controller } from "stimulus"

export default class extends Controller {
  initialize(){
    this.renderWidget = () => {
      Funifier.init(
        {
          apiKey: gon.funifier_key,
          language: "en-US",
        },
        () => {
          Funifier.auth.authenticate(
            {
              grant_type: "password",
              player: this.data.get("user"),
              password: "password",
            },
            (err, data) => {
              Funifier.widget.render(
                {
                  widget: "F01B_V2",
                  selector: ".js-main-side-nav__content-header-funifier",
                  bind: "on",
                  player: this.data.get("user"),
                  domain: this.data.get("url"),
                  show_badge: true,
                },
                function (err, data) {}
              )
            }
          );
        }
      )
    }
  }

  connect() {
    if(window.Funifier){
      this.renderWidget()
    } else {
      let script = document.getElementById("funifier-script");
      if (script) {
        script.onload = () => {
          this.renderWidget();
        };
      }
    }
  }
}
