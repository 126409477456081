import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.info = JSON.parse(this.data.get("data"))
    this.current_user = this.info.user
    this.post = this.info.post
    this.gamification_action_logs_path = this.info.gamification_action_logs_path
  }

  connect() {
    $(".article__cover, .thumb__cover").mouseover(function() {
      amplitude.getInstance().logEvent('Hovered blog post image',
      {
        'blog_title':$(this).attr("alt"),
        'url':document.location.href,
        'page_name':document.title,
        'blog_category':$(this).data('category')
      });
    });

    $(".article__link, .thumb__title").mouseover(function() {
      amplitude.getInstance().logEvent('Hovered blog post link',
      {
        'blog_title':$(this)[0].text,
        'url':document.location.href,
        'page_name':document.title,
        'blog_category':$(this).data('category')
      });
    });

    $(".article__cover, .thumb__cover").on("click", function() {
      amplitude.getInstance().logEvent('Clicked blog post image',
      {
        'blog_title':$(this).attr("alt"),
        'url':document.location.href,
        'page_name':document.title,
        'blog_category':$(this).data('category')
      });
    });

    $(".article__link, .thumb__title").on("click", function() {
      amplitude.getInstance().logEvent('Clicked blog post link',
      {
        'blog_title':$(this)[0].text,
        'url':document.location.href,
        'page_name':document.title,
        'blog_category':$(this).data('category')
      });
    });

    $(".badge").on("click", function() {
      const eventName = 'Clicked Category badge';
      const properties = {
        'page_name': document.title
      };

      amplitude.getInstance().logEvent(
        eventName,
        {
          'category_name': $(this)[0].innerText,
          'url': document.location.href,
          'page_name': document.title
        },
        () => logAmplitudeEvent(eventName, properties)
      );
    });


    let complete_scroll = false;
    let partial_scroll = false;
    let article_scroll = false;

    let FeatureArticleTag = tag => tag.slug.match(/^([a-z]|\d)*-article-\d*$/)

    

    function reachedEndOfArticle() {
      let desktopMinWidth = 992
      let mobileNavbarHeight = 72

      let checkpointDiv = document.getElementsByClassName('article__author')[0]
      let distanceToTop = checkpointDiv ? checkpointDiv.getBoundingClientRect().top : 0
      let padding = window.innerWidth < desktopMinWidth ? mobileNavbarHeight : 0

      return distanceToTop < (window.innerHeight - padding)
    }

    this.check_page_scroll = () => {
      if(!complete_scroll && ($(window).scrollTop() >= $("main").offset().top + $('main').outerHeight() - window.innerHeight)) {
        const eventName = 'Scrolled to end of page';
        amplitude.getInstance().logEvent(
          eventName,
          {
            'url': document.location.href,
            'page_name': document.title
          },
          () => logAmplitudeEvent(eventName)
        );
        complete_scroll = true;
      }
      
      if (!article_scroll && reachedEndOfArticle()) {
        if (this.current_user && this.post && this.post.tags && this.post.tags.some((t) => t.slug === "article_of_the_day")){
          $.ajax({
            method: 'POST',
            url: this.gamification_action_logs_path,
            data: {
              action_log: {
                action: 'Read article of the day'
              }
            }
          })
        }
        if (this.current_user && this.post && this.post.tags.some(FeatureArticleTag)){
          $.ajax({
            method: 'POST',
            url: this.gamification_action_logs_path,
            data: {
              action_log: {
                action: 'Read a featured article',
                properties: {
                  tag: `${this.post.tags.map((tags) => tags.slug).join(', ')}`
                }
              }
            }
          })
        }
        article_scroll = true;
      }

      if(!partial_scroll) {
        if($(window).scrollTop() >= $("main").offset().top + $('main').outerHeight() / 2 - window.innerHeight) {
          const eventName = 'Partially scrolled page';
          amplitude.getInstance().logEvent(
            eventName,
            {
              'url': document.location.href,
              'page_name': document.title
            },
            () => logAmplitudeEvent(eventName)
          );
            partial_scroll = true;
        }
      }
    }

    $(window).scroll(this.check_page_scroll)

    var monitor = setInterval(() => {
      var elem = document.activeElement;
      if(elem && elem.id == 'media-video'){
          clearInterval(monitor);
          amplitude.getInstance().logEvent('Played video',
          {
            'url':document.location.href,
            'page_name':document.title
          });
          if (this.current_user && this.post){
            $.ajax({
              method: 'POST',
              url: this.gamification_action_logs_path,
              data: {
                action_log: {
                  action: 'Watched a video',
                  properties: {
                    category: `${this.post.categories.map((cat) => cat.slug).join(', ')}`
                  }
                }
              }
            })
          }
      }
    }, 100);

    $(".search_result_title, .search_result_image_wrapper").on('click', function(){
      amplitude.getInstance().logEvent('Clicked search result',
      {
        'blog_title': $(this).attr('data-title'),
        'blog_category': $(this).attr('data-category'),
      });
    });

    function taxtime_blogpost_link_track(link) {
      if (link == "https://www.irs.gov/filing/free-file-do-your-federal-taxes-for-free") {
        amplitude.getInstance().logEvent('Clicked TTQ link',
        {
          'url':document.location.href,
          'page_name':document.title
        });
      }
    }

    $("a").on("click", function(e){
      taxtime_blogpost_link_track($(this)[0].href);
    })
  }

  disconnect(){
    $(window).off("scroll", this.check_page_scroll)
  }
}
