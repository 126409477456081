import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    shadowbanned: Boolean,
  };

  initialize() {
    if (this.shadowbannedValue) this.activateZombieMode();
  }

  activateZombieMode() {
    this.element.addEventListener("click", this.handleClick, true);
    this.element.classList.add("tw-pointer-events-none");
  }

  handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }
}