import { Controller } from "stimulus"

//Some functionality could be extracted and modularized. I.e the select-button logic
export default class extends Controller {
  static get targets() {
    return ["selectButton", "amount", "confirmationAmount", "step", "nextButton"]
  }

  connect() {
    this.stepStack = []
    this.currentStep = 'preselectedScreen'
    this.showStep(this.currentStep)
  }

  select(event) {
    let button = event.target
    let value = button.dataset.value
    this.styleButtons(value)
    this.setAmount(value)
    this.enableButton()
  }

  styleButtons(value) {
    this.selectButtonTargets.forEach((button, index) => {
      if (this.selectButtonTargets[index].dataset.value != value) {
        button.classList.remove('c-select-button--active')
      } else {
        button.classList.add('c-select-button--active')
      }
    });
  }

  setAmount(value) {
    let decimals = ''
    if (isNaN(value)) this.amountTarget.value = ''
    if (!`${value}`.includes('.')) decimals = '.00'

    this.amountTarget.value = value
    this.confirmationAmountTarget.innerHTML = `$${value}` + decimals
  }

  setCustomAmount() {
    this.setAmount(this.amountTarget.value)
  }

  enableButton() {
    this.nextButtonTarget.disabled = false;
  }

  continue() {
    let nextStep = this.isAmountSet() ? 'confirmationScreen' : 'customAmountScreen'
    this.showStep(nextStep)
    this.stepStack.push(this.currentStep)
    this.currentStep = nextStep
  }

  goBack() {
    if (this.isFirstStep()) {
      window.history.back()
    } else {
      let lastStep = this.stepStack.pop()
      this.showStep(lastStep)
      this.currentStep = lastStep
    }
  }

  showStep(stepName) {
    this.stepTargets.forEach((step) => {
      step.hidden = (step.dataset.stepName != stepName)
    })
  }

  isAmountSet() {
    return this.amountTarget.value != ''
  }

  isFirstStep() {
    return this.currentStep == "preselectedScreen"
  }
}