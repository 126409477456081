import { Controller } from 'stimulus';
export default class extends Controller {
  static get targets() {
    return ['title', 'body'];
  }

  initialize() {
    this.expanded = false;
    this.calculateDimensions()
  }

  calculateDimensions() {
    this.collapsedHeight = this.titleTarget.getBoundingClientRect().height;
    this.expandedHeight =
      this.bodyTarget.getBoundingClientRect().height + this.collapsedHeight;
    this.element.style.height = `${this.collapsedHeight}px`;
  }

  toggle() {
    this.expanded = !this.expanded;
    this.titleTarget.setAttribute('aria-expanded', this.expanded);
    this.titleTarget.classList.toggle(this.data.get('rotateIconClass'));

    this.expanded
      ? (this.element.style.height = `${this.expandedHeight}px`)
      : (this.element.style.height = `${this.collapsedHeight}px`);
  }
}
