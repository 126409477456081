import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.carouselInit = () => {
      let winW = $(window).width();

      //Carousel  at register window
      if (winW < 767) {
        if (!$(".carousel").hasClass("slick-initialized")) {
          $(".carousel").slick({
            centerMode: true,
            centerPadding: "60px",
            autoplay: true,
            infinite: true,
            speed: 500,
            prevArrow: false,
            nextArrow: false,
            dots: true,
            slidesToShow: 1,
          });
        }
      } else if ($(".carousel").hasClass("slick-initialized")) {
        $(".carousel").slick("unslick");
      }
    };
  }

  connect() {
    this.carouselInit();
    $(window).on("resize", this.carouselInit);
  }

  disconnect() {
    $(window).off("resize", this.carouselInit)
  }
}
