import { Controller } from "stimulus"

export default class extends Controller {
  connect () {
    let dom_inputs_to_validate_not_empty = $(".js-validate-empty");
    let dom_submit_btn = $(".js-submit-btn");
    let errors = new Set();

    amplitudeSetUp();

    if (sessionStorage.getItem("PayeeVisited")) {
      sessionStorage.removeItem("PayeeVisited");
      window.location.reload(true);
    } else {
      sessionStorage.setItem("PayeeVisited", "True");
    }

    dom_inputs_to_validate_not_empty.each(function(index, elem){
      if(input_is_empty($(this)))
        errors.add("js-error-empty-" + dom_inputs_to_validate_not_empty.index($(this)));
    });

    function toggle_error_msg(invalid_input, error_id, msg, show_error){
      let error_elem = $("." + error_id);

      if(!error_elem.length && show_error){
        invalid_input.addClass("highlight__input");
        $("<div class=\'error highlight__text " + error_id + "'>" + msg + "</div>").insertAfter(invalid_input);
      }else if (error_elem.length && !show_error){
        error_elem.remove();
        invalid_input.removeClass("highlight__input");
      }
    }

    function input_is_empty(elem){
      return elem.val() == "" || elem.val() == null;
    }


    function show_error_if_input_empty(elem, errors_set, error_id, msg){
      let display_error = input_is_empty(elem);
      toggle_error_msg(elem, error_id, msg, display_error, "empty-text");

      if(display_error)
        errors_set.add(error_id);
      else{
        errors_set.delete(error_id);
      }
    }


    dom_inputs_to_validate_not_empty.on("input change focusout", function(){
      let empty_error_id = "js-error-empty-" + dom_inputs_to_validate_not_empty.index($(this));

      show_error_if_input_empty($(this), errors, empty_error_id, 'This must not be empty');

      dom_submit_btn.attr("disabled", errors.size > 0);
    });

    function amplitudeSetUp() {
      if (window.amplitude)
        triggerAmplitudeEvents()
      else
        window.addEventListener('amplitude loaded', triggerAmplitudeEvents);

      document.addEventListener("turbo:before-cache", function() {
        window.removeEventListener('amplitude loaded', triggerAmplitudeEvents)
      })
    }

    function triggerAmplitudeEvents() {
      amplitude.getInstance().logEvent('HyperWallet - Landed on information verification page',
      {
        'url':document.location.href,
      });
    }
  }
}