import { Controller } from "stimulus"

export default class extends Controller {

  track(event) {
    const oneStopGuide = event.currentTarget
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const funifierPath = '/funifier/action_logs'
    const headers = new Headers({
      'X-CSRF-TOKEN': csrfToken,
      'Content-Type': 'application/json'
    });

    fetch(funifierPath, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        action_log: {
          action: 'Bookmarked One Stop Guide',
          attributes: {
            name: oneStopGuide.dataset.theme,
          }
        }
      })
    })
    .then(response => {
      if (response.status != 204) console.error(`Unexpected response from: ${funifierPath}`);
    })
    amplitude.getInstance().logEvent(oneStopGuide.dataset.amplitudeEvent);
  }
}