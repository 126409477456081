import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output", "input", "label", "filename", "content" ]
  static classes = [ "display" ]

  readURL() {
    let input = this.inputTarget
    let output = this.outputTarget
    let label = this.labelTarget
    let content = this.contentTarget
    let filename = this.filenameTarget
    let filenameMaxCharacters = 30
    let filenameLastCharacters = -6
    let displayClass = this.displayClass
    
    if (input.files && input.files[0]) {
      let reader = new FileReader()
      let idName = input.files[0].name
      
      reader.onload = function () {
        output.style.backgroundImage = "url('" + reader.result + "')"
        output.classList.remove(displayClass)
        label.classList.add(displayClass)
        content.classList.remove(displayClass)
        let split = idName.split(/\.(?=[^\.]+$)/)
        let subname = split[0]
        let extension = split[1]
        if (subname.length > filenameMaxCharacters) {

          let subnameLeft = subname.substr(0, filenameMaxCharacters + filenameLastCharacters);
          let subnameRight = subname.substr(filenameLastCharacters)
          return filename.innerText = "[" + subnameLeft + "..." + subnameRight + "." + extension + "]"
        }
        filename.innerText = "[" + idName + "]"
      }

      reader.readAsDataURL(input.files[0]);
    }
  }

  cleanFile() {
    let filename = this.filenameTarget
    let displayClass = this.displayClass

    this.inputTarget.value = ""
    this.outputTarget.style.backgroundImage = ""
    filename.innerText = ""
    this.outputTarget.classList.add(displayClass)
    this.labelTarget.classList.remove(displayClass)
    this.contentTarget.classList.add(displayClass)
  }

}