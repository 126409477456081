import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ["button", "tooltip"]
  }

  async share() {
    const shareData = {
      title: this.buttonTarget.dataset.title,
      url: this.buttonTarget.dataset.url
    }

    if (navigator.share) {
      navigator.share(shareData)
      .then(() => {
        amplitude.getInstance().logEvent(this.buttonTarget.dataset.amplitudeEvent, {social_share_method: "native"})
      })
      .catch(console.error)
    } else {
      //Fallback method
      this.copyToClipboard(shareData.url)
    }
  }

  copyToClipboard(url){
    navigator.clipboard.writeText(url)
    .then(() => {
      amplitude.getInstance().logEvent(this.buttonTarget.dataset.amplitudeEvent, {social_share_method: "copied to clipboard"})
      this.showTooltip()
    })
    .catch(console.error)
  }

  showTooltip() {
    this.tooltipTarget.classList.remove("tw-hidden")
    setTimeout(() => {
      this.tooltipTarget.classList.add("tw-hidden")
    }, 2000);
  }

}
