import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.scroller = scrollama();
    if ($(".js-lazy-img").length > 0) {
      this.scroller
        .setup({
          step: ".js-lazy-img",
          offset: 0.8,
        })
        .onStepEnter((response) => {
          var el = $(response.element);
          if (!el.hasClass("loaded")) {
            el.removeClass("js-lazy-img");
            var img = $("<img>")
              .attr("src", el.data("src"))
              .attr("title", el.data("title"))
              .addClass(el.data("class"))
              .hide();
            el.addClass("loaded");

            if (el.data("replace")) {
              el.replaceWith(img);
            } else {
              el.append(img);
            }
            img.fadeIn();
          }
        });

      $(window).on("resize", this.scroller.resize);
    }
  }

  disconnect() {
    $(window).off("resize", this.scroller.resize);
  }
}
