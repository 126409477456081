import { Controller } from 'stimulus';
export default class extends Controller {
  static get targets() {
    return ['wrapper']
  }

  connect() {
    this.featureId = this.wrapperTarget.dataset.featureId;
    this.timeout = this.wrapperTarget.dataset.timeout;
    this.init();
  }

  init() {
    this.autoShow();
  }

  autoShow() {
    if (this.timeout <= 0) return
    setTimeout(() => {
      this.show();
    }, this.timeout);
  }

  show() {
    if (this.featureAlreadyShown()) return
    this.wrapperTarget.classList.add('feature-discovery--visible');
    window.localStorage.setItem(`${this.featureId}-feature-discovery-shown`, true);
  }

  hide() {
    this.wrapperTarget.classList.remove('feature-discovery--visible');
  }

  featureAlreadyShown() {
    return window.localStorage.getItem(`${this.featureId}-feature-discovery-shown`)
  }
}