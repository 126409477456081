import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.initializeSmartBannerTracker();
  }

  initializeSmartBannerTracker() {
    let handleSmartBannerView = () => {
      const viewButton = document.querySelector('.smartbanner__button');
      if (viewButton) {
      viewButton.addEventListener('mouseup', () => {
          this.trackAnalyticsEvent("clicked_app_download_banner");
        });
      }
    }

    document.addEventListener('smartbanner.view', handleSmartBannerView);
  }

  trackAnalyticsEvent(event) {
    let event_name = ""

    if (typeof event === 'string') {
      event_name = event;
    } else if (event && event.target && event.target.dataset) {
      event_name = event.target.dataset.eventName;
    } else {
      console.warn('Invalid event object or event name');
      return;
    }

    const data = {
      event: event_name,
    };

    dataLayer.push(data)
  }
}