import { Controller } from "stimulus"
const ENVIRONMENT = document.querySelector('meta[name="environment"]').content

export default class extends Controller {
  static get targets() {
    return ["submit", "ssn", "hiddenSsn"]
  }

  connect() {
    if (this.hasHiddenSsnTarget) {
      this.isSsnMasked = false
      document.addEventListener('turbo:before-fetch-request', (event) => {
        event.preventDefault()
        this.maskSsn()
        event.detail.resume()
      })
    }
  }

  maskSsn() {
    let ssnInput = this.ssnTarget.value
    let productionRegExp = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/
    let developmentRegExp = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}|^2222$/
    let ssnRegExp = (ENVIRONMENT == 'production') ? productionRegExp : developmentRegExp

    let isSsnValid = ssnInput.match(ssnRegExp)

    if (isSsnValid) {
      this.hiddenSsnTarget.value = ssnInput
      this.ssnTarget.value = ssnInput.replace(/\d{3}-\d{2}/, '***-**')
      this.isSsnMasked = true
    }
  }

  unmaskSsn() {
    if (this.isSsnMasked) {
      this.ssnTarget.value = this.hiddenSsnTarget.value
      this.isSsnMasked = false
    }
  }

  formatSsnInput(event) {
    let ssnInput = this.ssnTarget
    let nonAllowedCharactersRegex = /[^\d-]/g
    let isInputKeyBackslash = (event.key == 'Backspace' || event.keyCode == 8)
    let isInputKeyHyphen = (event.key == '-' || event.keyCode == 189)

    removeIvalidCharacters()
    removeInvalidHyphens()
    addHyphenSeparator()
    deleteHyphenOnBackslash()
    removeCharacterOverAllowedLength()

    function removeIvalidCharacters() {
      ssnInput.value = ssnInput.value.replace(nonAllowedCharactersRegex, '')
    }

    function removeInvalidHyphens() {
      if (isInputKeyHyphen && (ssnInput.value.length != 4 || ssnInput.value.length != 7))
        ssnInput.value = ssnInput.value.slice(0, -1)
    }

    function addHyphenSeparator() {
      if ((ssnInput.value.length == 3 || ssnInput.value.length == 6) && !isInputKeyBackslash && !ssnInput.value.match(/^222$/))
        ssnInput.value = ssnInput.value + '-'
    }

    function deleteHyphenOnBackslash() {
      if (isInputKeyBackslash) {
        if (ssnInput.value.length == 3 || ssnInput.value.length == 6)
          ssnInput.value = ssnInput.value.slice(0, -1)
      }
    }

    function removeCharacterOverAllowedLength() {
      if (ssnInput.value.length > 11)
        ssnInput.value = ssnInput.value.slice(0, 11)
    }
  }
}
