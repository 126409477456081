import { Controller } from "stimulus"

export default class extends Controller {       
  static get targets(){
    return ["form", "submit"]
  }

  connect() {
    for (let formField of this.formTarget.elements) {
      formField.addEventListener('keyup', (e) => this.submitIfEnter(e));
    }
  }

  submitIfEnter(e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.submitTarget.click();
    }
  }
  
  checkRecaptcha() {
    grecaptcha.execute();
  }
}
