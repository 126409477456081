import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    item: String
  }

  logActivity() {
    $.ajax({
      method: 'POST',
      url: "/funifier/action_logs",
      data: {
        action_log: {
          action: this.itemValue,
          attributes: {}
        } 
      }
    });
  }
}
