import { Controller } from "stimulus"

export default class extends Controller {

  static get targets() {
    return ["formattedInput", "realInput"]
  }

  formatCurrency() {
    let input = this.formattedInputTarget
    let input_val = this.formattedInputTarget.value

    if (input_val === "") { return; }

    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);

      left_side = this.parseToCurrency(left_side);
      right_side = this.parseToCurrency(right_side);

      right_side = right_side.substring(0, 2);
      input_val = "$" + left_side + "." + right_side;
    } else {
      input_val = this.parseToCurrency(input_val);
      input_val = "$" + input_val;
    }

    this.formattedInputTarget.value = input_val
    this.fillRealInput(input_val)
  }

  parseToCurrency(number) {
    return number.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  parseToNumber(number) {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/[^\d.]*/g, "")
  }

  fillRealInput(value) {
    this.realInputTarget.value = this.parseToNumber(value)
    this.realInputTarget.dispatchEvent(new Event('input'));
  }
}
