import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ["step", "nextButton", "backButton"]
  }

  static get values() {
    return { index: Number }
  }

  continue() {
    if (this.isLastStep()) return;
    this.indexValue++
  }

  goBack() {
    if (this.isFirstStep()) window.history.back();
    this.indexValue--
  }

  indexValueChanged() {
    this.showStep()
    this.triggerEvent()
  }

  showStep() {
    this.stepTargets.forEach((step, index) => {
      step.hidden = (index != this.indexValue)
    });
  }

  triggerEvent() {
    const event = new CustomEvent('viewChange')
    window.dispatchEvent(event)
  }

  isLastStep() {
    return (this.indexValue + 1 == this.stepTargets.length)
  }

  isFirstStep() {
    return this.indexValue <= 0
  }
}