import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.resizeEvent = () => {
      $('.thumb--ratio .thumb__cover').each(function () {
        $(this).width($(this).parents('.thumb').outerHeight());
      });
    }
  }

  connect() {
    $('.thumb--ratio .thumb__cover').each(function () {
      $(this).width($(this).parents('.thumb').outerHeight());
    });
    
    $(window).on('resize', this.resizeEvent);
  }

  disconnect() {
    $(window).off("resize", this.resizeEvent)
  }
}
