import { Controller } from "stimulus"

export default class extends Controller { 
  static values = {
    src: String,
  }

  connect() {
    let phoneNumberField = document.getElementById("phone_number")
    let script = document.createElement('script');

    script.addEventListener("load", function() {
      let phones = [
        { mask: "+#(###) ###-####" },
        { mask: "+#(###) ###-##############" },
      ];
      
      Inputmask({
        mask: phones,
        greedy: false,
        definitions: { "#": { validator: "[0-9]", cardinality: 1 } },
      }).mask(phoneNumberField);
    });
    
    script.type = "text/javascript";
    script.nonce = Rails.default.cspNonce() // Methods from the Rails variable are now nested within the "default" object
    script.src = this.srcValue;

    document.getElementsByTagName('head')[0].appendChild(script);
    }
  }
