// Extracted from tailwindcss-stimulus-components v2.1.1

import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ["tab", "panel", "secondaryPanel"]
  }

  connect() {
    this.activeTabClasses = (this.data.get('activeTab') || 'active').split(' ')
    this.inactiveTabClasses = (this.data.get('inactiveTab') || 'inactive').split(' ')
    this.index = this.getIndex()
    if (this.anchor) this.index = this.tabTargets.findIndex((tab) => tab.id === this.anchor)
    this.showTab()
  }

  change(event) {
    event.preventDefault()

    this.scrollIntoView(event.currentTarget)

    // If target specifies an index, use that
    if (event.currentTarget.dataset.index) {
      this.index = event.currentTarget.dataset.index

    // If target specifies an index, use that
    } else if (event.currentTarget.dataset.id) {
      this.index = this.tabTargets.findIndex((tab) => tab.id == event.currentTarget.dataset.id)

    // Otherwise, use the index of the current target
    } else {
      this.index = this.tabTargets.indexOf(event.currentTarget)
    }

    window.dispatchEvent(new CustomEvent('tsc:tab-change'))
  }

  showTab() {
    this.tabTargets.forEach((tab) => {
      let tab_index = parseInt(tab.dataset.index)

      const panel = this.panelTargets.find((panel) => panel.dataset.panelIndex == tab.dataset.index )
      const secondaryPanel = this.secondaryPanelTargets.find((secondaryPanel) => secondaryPanel.dataset.secondaryPanelIndex == tab.dataset.index )

      this.togglePanel(panel, tab, tab_index)
      this.togglePanel(secondaryPanel, tab, tab_index)
    })

    if (this.tabTargets.length == 1) document.querySelector('.c-navigation-tabs__bar').classList.add('display__none')
  }

  togglePanel(panel, tab, index){
    if (index === this.index) {
      panel.classList.remove('display__none')
      tab.classList.remove(...this.inactiveTabClasses)
      tab.classList.add(...this.activeTabClasses)

      this.scrollIntoView(tab)

      if (tab.id) {
        location.hash = tab.id
      }
    } else {
      panel.classList.add('display__none')
      tab.classList.remove(...this.activeTabClasses)
      tab.classList.add(...this.inactiveTabClasses)
    }
  }

  scrollIntoView(element){
    if (!document.documentElement.hasAttribute("data-turbo-preview")){
      window.scrollTo({top: 0});
      element.scrollIntoView({behavior: "smooth", block: "end", inline: "center"});
    }
  }

  defaultIndex(){
    return this.tabTargets[0].dataset.index
  }

  getIndex() {
    let paramIndex = parseInt(window.location.search.slice(-1))

    if (paramIndex >= 0) {
      return paramIndex
    } else {
      return this.sessionIndex() || this.defaultIndex()
    }
  }

  sessionIndex() {
    return window.sessionStorage.getItem(`${window.location.pathname}_tab_index`)
  }

  get index() {
    return parseInt(this.data.get('index') || this.defaultIndex())
  }

  set index(value) {
    this.data.set('index', (value >= 0 ? value : 0))
    window.sessionStorage.setItem(`${window.location.pathname}_tab_index`, value)
    this.showTab()
  }

  get anchor() {
    return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
  }
}
