import { Controller } from "stimulus"

export default class extends Controller {
  track(e){
    fetch('/click_interactions', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        id: e.currentTarget.dataset.interactableId,
        type: e.currentTarget.dataset.interactableType,
      })
    })
  }
}
