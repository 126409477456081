import { Controller } from "stimulus"

export default class extends Controller {
  static values = { title: String, href: String }

  click_cta() {
    const href = this.hrefValue

    if (href == "") return false;

    if (href.startsWith("#")) {
      scroll_down_to_element(href);
    } else {
      redirect_to_url();
    }

    function scroll_down_to_element(element_id) {
      let element = document.querySelector(element_id);
      element.scrollIntoView({ behavior: 'smooth', block: 'center'});
    }

    function redirect_to_url() {
      window.open(href, '_blank');
    }
  }

}