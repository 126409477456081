import { Controller } from "stimulus";
import Swiper, { Autoplay } from "swiper";

export default class extends Controller {
  swiper = null;

  static values = {
    options: Object,
  };

  get defaultOptions() {
    return {};
  }

  connect() {
    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue,
      modules: [Autoplay],
      allowTouchMove: false,
      pagination: {
        el: ".swiper-pagination",
      },
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }
    });
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = null;
  }
}
