import { Controller } from "stimulus"

export default class extends Controller {

  connect(){
    this.allow_dismiss_dismissable_alerts();
    this.current_index = 0;
    this.button_icon_src = this.data.get('alertsCloseIconSrc');
    this.dismiss_url = this.data.get('dismissUrl');
    this.dismissed_time = this.data.get('dismissedTime');

    if(!this.data.has('notificationsDisplayed')){
      this.notifications = JSON.parse(this.data.get('alerts'));

      if(this.notifications){
        this.show_notifications(0, [0]);
      }
    }
  }

  disconnect(){
    this.clean_container_content();
    this.data.set('notificationsDisplayed', 'true');
  }

  allow_dismiss_dismissable_alerts(){
    $('.alert__btn').on('click', (e) => {
      this.send_dissmiss_notification(e['currentTarget'].getAttribute('name'));
    });
  }

  clean_container_content(){
    document.getElementsByClassName("js-alert-notifications-container")[0].innerHTML = '';
  }



  send_dissmiss_notification(notification_id){
    $.ajax({
      url: this.dismiss_url,
      type: 'PUT',
      data: {
        id: notification_id,
        alert: {
          dismissed: this.dismissed_time
        }
      }
    });
  }

  remove_notification(notification){
    if(notification){
      notification.removeClass("alert-notification--show")
      notification.addClass("alert-notification--hide");
      this.send_dissmiss_notification(notification.attr('id'));

      return new Promise(resolve => setTimeout( function(){
        notification.remove();
        resolve();
      }, 600 ));
    }
  }

  generate_notification_html(id, msg, is_dismissable=true, action_href=null){

    let notification_html = '<div id="' + id + '"class="alert alert-notification alert-notification--show" role="alert">';

    if(action_href)
      notification_html += '<a href"' + action_href + '">' + msg + '</a>'
    else
      notification_html += msg


    if(is_dismissable)
      notification_html += '<button name="23" id="alert-' + id + '-button" type="button"  class="alert__btn"  data-original-title="" title=""><svg class="icon icon-x" role="img" data-original-title="" title=""><use xlink:href="' + this.button_icon_src + '#icon-x" data-original-title="" title=""></use></svg></button>' ;

    notification_html += '</div>';

    return notification_html;
  }

  step_to_next_notification(notification_dom, index){
    this.remove_notification(notification_dom).then( (e) => { this.show_notifications(index + 1) });
  }

  get_notifications_container(){
    return document.getElementsByClassName(".js-alert-notifications-container")[0];
  }

  show_notifications(index){
    if(!this.data.has('notificationsDisplayed') && this.current_index == index  && index < this.notifications.length ){
      const notification_id = this.notifications[index]["id"];
      const notification_msg = this.notifications[index]["display_text"];


      let notification_html = this.generate_notification_html(notification_id, notification_msg);
      $(document.getElementsByClassName("js-alert-notifications-container")[0]).append(notification_html);

      let notification_dom =  $("#" + notification_id);
      let notification_dismiss_btn = $("#alert-" + notification_id + "-button");

      this.current_index = index + 1;

      setTimeout( () => {
        this.remove_notification(notification_dom).then( (e) => { this.show_notifications(index + 1) });
      }, 5000, notification_dom, index);

      notification_dismiss_btn.on("click", () => {
        this.remove_notification(notification_dom).then( (e) => { this.show_notifications(index + 1) });
      });
    }
  }
}
