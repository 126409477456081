import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "phone" ]

  resendPhoneToken(event){
    const phone = this.phoneTarget.dataset.phone

    if(phone) {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const path = "/phone_verifications"
      const headers = new Headers({
        'X-CSRF-TOKEN': csrfToken,
        'Content-Type': 'application/json'
      });

      fetch(path, {
        method: 'POST',
        headers,
        body: JSON.stringify({ profile: { phone, validation: "false" } })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        flash_message("warning", "Code was resent successfully. Check your phone.");
      })
      .catch(error => {
        flash_message("danger", "An error prevented regenerating the phone code. Please try again later.");
      });
    }
  }
}