import { Controller } from "stimulus"

export default class extends Controller {
  static get targets(){
    return ["form","confirmInput","checkboxInput","passwordInput","passwordStrengthBar", "nameInput", "submit"]
  }
  static values = {
    skipRecaptcha: Boolean
  }

  initialize(){
    this.fieldsCounter = 0;
    this.errorsHash = {fields:{}, errorsCounter: 0};
    this.areDynamicValidationsRunning = false;
    this.isFormValid = true;
  }

  connect(){
    let requiredFieldSelectors = 'textarea:required, input:required';
    this.requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);

    this.submitTarget.setAttribute('disabled','disabled');

    this.validateForm((e)=>{
      this.validateFieldsWith(this.requiredFields, 'This field cannot be blank', 'empty-text', this.validateFieldIsNotBlank.bind(this), false);
      this.validateFieldsWith(this.checkboxInputTargets, 'This should be checked', 'is-not-checked', this.validateCheckboxFieldIsChecked.bind(this), false);
    });

    for(let pwdStrengtBar of this.passwordStrengthBarTargets){
      let pwdStrengthBarTarget = this.getPasswordStrengthIndicatorTarget(pwdStrengtBar);
      pwdStrengthBarTarget.addEventListener('input', (e)=>{
        this.setPasswordStrengthIndicator(pwdStrengtBar);
      })
    }

    for(let checkbox of this.checkboxInputTargets){
      checkbox.addEventListener('change', (e)=>{
        this.validateForm( (e2)=>{
          this.validateFieldsWith([e.currentTarget], 'This should be checked', 'is-not-checked', this.validateCheckboxFieldIsChecked.bind(this), false);
        });
      });
    }

    for(let rField of this.requiredFields){
      rField.addEventListener('input', (e) =>{
        this.validateForm((e2)=>{
          this.validateFieldsWith([e.currentTarget], 'This field cannot be blank', 'empty-text', this.validateFieldIsNotBlank.bind(this), false);
        });
      });
    }

    this.submitTarget.addEventListener('click', (e) =>{
      this.startDynamicValidations();
      if(!this.isFormValid) {
        e.preventDefault();
      } else if (this.recaptchaExists()) {
        let eventName = new CustomEvent('checkRecaptcha');
        this.submitTarget.dispatchEvent(eventName);
      } else {
        let submitBtn = document.getElementsByClassName("js-recaptcha-form-btn")[0];
        submitBtn.setAttribute('type', 'submit')
        submitBtn.click();
      }
    });

    this.formTarget.addEventListener("submit", (e)=>{
      this.startDynamicValidations();
      if(!this.isFormValid) e.preventDefault();
    });
  }

  recaptchaExists() {
    if (this.skipRecaptchaValue) {
      return false;
    } else {
      return this.formTarget.classList.contains('js-invisible-recaptcha-form');
    }
  }

  startDynamicValidations(){
    if(!this.areDynamicValidationsRunning){
      this.validateForm((e)=>{
        this.validateFieldsWith(this.requiredFields, 'This field cannot be blank', 'empty-text', this.validateFieldIsNotBlank.bind(this));
        this.validateFieldsWith(this.checkboxInputTargets, 'This should be checked', 'is-not-checked', this.validateCheckboxFieldIsChecked.bind(this));
        this.validateFieldsWith(this.passwordInputTargets, 'Password must be medium or stronger. Multiple words, symbols, digits, or uppercase letters help.', 'pwd-strength',  this.validatePasswordFielIstStrongEnough.bind(this));
        this.validateFieldsWith(this.confirmInputTargets,'This confirmation field does not match', 'confirm-input', this.validateConfirmationFieldIsEqual.bind(this));
        this.removeUnwantedSpaces(this.nameInputTargets);
      });

      for(let rField of this.requiredFields){
        rField.addEventListener('input', (e) =>{
          this.validateForm((e2)=>{
            this.validateFieldsWith([e.currentTarget], 'This field cannot be blank', 'empty-text', this.validateFieldIsNotBlank.bind(this));
          });
        });
      }

      for(let passwordField of this.passwordInputTargets){
        passwordField.addEventListener('input', (e)=>{
          this.validateForm( (e2)=>{
            this.validateFieldsWith([e.currentTarget], 'Password must be medium or stronger. Multiple words, symbols, digits, or uppercase letters help.', 'pwd-strength',  this.validatePasswordFielIstStrongEnough.bind(this));
          });
        });
      }

      for(let confirmField of this.confirmInputTargets){
        confirmField.addEventListener('input', (e)=>{
          this.validateForm( (e2)=>{
            this.validateFieldsWith([e.currentTarget],'This confirmation field does not match', 'confirm-input', this.validateConfirmationFieldIsEqual.bind(this));
          });
        });

        let confirmingFieldId = confirmField.dataset.formValidationsConfirmInputFor;
        let confirmingFieldSelector = '*[data-form-validations-confirm-input-id=' + confirmingFieldId + ']';
        let confirmingField = this.formTarget.querySelectorAll(confirmingFieldSelector)[0];
        confirmingField.addEventListener('input', (e) => {
          this.validateForm( (e2)=>{
            this.validateFieldsWith([confirmField],'This confirmation field does not match', 'confirm-input', this.validateConfirmationFieldIsEqual.bind(this));
          });
        });
      }
    }
    this.areDynamicValidationsRunning = true;
  }

  validateForm(validationsCallback){
    validationsCallback();
    if (this.getAmountErrorsInErrorsHash() === 0){
      this.submitTarget.removeAttribute('disabled');
      this.isFormValid = true;
      return true;
    }

    this.submitTarget.setAttribute('disabled','disabled');
    this.isFormValid = false;
    return false;
  }

  validateFieldsWith(fields, msg, validationType, isFieldValidCallback, showErrorOnInvalid=true){
    for(let field of fields){
      let fieldId = this.setDataIdToField(field);
      let isFieldValid = isFieldValidCallback(field);

      if(isFieldValid){
        this.removeErrorOfField(fieldId, validationType);
        this.toggleErrorMsg(field, msg, false, validationType);

        if(this.getAmountErrorsInField(fieldId) === 0) this.highlightFieldWithError(field,false);

      } else {
        this.addErrorOfField(fieldId, validationType);
        if(showErrorOnInvalid){
          this.highlightFieldWithError(field);
          this.toggleErrorMsg(field, msg, true, validationType);
        }
      }
    }
  }

  removeUnwantedSpaces(nameFields) {
    for (let name of nameFields) {
      name.value = name.value.trim()
    }
  }

  getPasswordStrengthIndicatorTarget(strengthBarElem){
    let fieldToCheckDataId = strengthBarElem.dataset.formValidationsStrengthBarFor;
    let passwordFieldToCheckSelector = '*[data-form-validations-strength-bar-target=' + fieldToCheckDataId +  ']';
    return this.formTarget.querySelectorAll(passwordFieldToCheckSelector)[0];
  }

  setPasswordStrengthIndicator(strengthBarElem){
    let passwordFieldToCheck = this.getPasswordStrengthIndicatorTarget(strengthBarElem)
    let passwordScore = this.getPasswordScore(passwordFieldToCheck.value);
    let passwordLength = passwordFieldToCheck.value.length;
    let passwordStrengthTextDOM = strengthBarElem.querySelectorAll('.js-password-strength-bar__text')[0];
    strengthBarElem.classList.remove('strength--weak', 'strength--medium', 'strength--strong');
    if (passwordScore <= 1 && passwordLength > 0) {
      strengthBarElem.classList.add('strength--weak');
      passwordStrengthTextDOM.innerHTML = 'Weak';
    } else if (passwordScore == 2) {
      strengthBarElem.classList.add('strength--medium');
      passwordStrengthTextDOM.innerHTML = 'Medium';
    } else if (passwordScore >= 3) {
      strengthBarElem.classList.add('strength--strong');
      passwordStrengthTextDOM.innerHTML = 'Strong';
    }
  }

  setDataIdToField(field){
    let fieldDataId = field.dataset.formValidationsFieldId;
    if(!fieldDataId){
      field.dataset.formValidationsFieldId = this.fieldsCounter;
      return this.fieldsCounter++;
    }
    return fieldDataId;
  }

  getDataIdOfField(field){
    return field.dataset.formValidationsFieldId;
  }

  markFieldWithError(field){
    let fieldDataId = this.getDataIdOfField(field);
    if(fieldDataId) this.fieldsWithErrors.add(fieldDataId);
  }

  isFieldMarkedWithError(field){
    let fieldDataId = this.getDataIdOfField(field);
    if(fieldDataId) {
      return this.fieldsWithErrors.has(fieldDataId);
    }
    return false;
  }

  addErrorOfField(fieldId, errorType){
    if(!this.errorsHash.fields.hasOwnProperty(fieldId)) {
      this.errorsHash.fields[fieldId] = {errors:new Set(), errorsCounter: 0};
    }
    let fieldErrorsObj = this.errorsHash.fields[fieldId];
    if(!fieldErrorsObj.errors.has(errorType)){
      fieldErrorsObj.errors.add(errorType);
      fieldErrorsObj.errorsCounter++;
      this.errorsHash.errorsCounter++;
    }
  }

  removeErrorOfField(fieldId, errorType){
    if(this.errorsHash.fields.hasOwnProperty(fieldId)){

      let fieldErrorsObj = this.errorsHash.fields[fieldId];
      if(fieldErrorsObj.errors.has(errorType)){
        fieldErrorsObj.errors.delete(errorType);
        fieldErrorsObj.errorsCounter--;
        this.errorsHash.errorsCounter--;
      }
    }
  }

  isFieldWithErrorOf(fieldId, errorType){
    if(this.errorsHash.fields.hasOwnProperty(fieldId)){
      let fieldErrorsSet = this.errorsHash.fields[fieldId].errors;
      return fieldErrorsSet.has(errorType);
    }
    return false;
  }

  getAmountErrorsInField(fieldId, errorType){
    if(this.errorsHash.fields.hasOwnProperty(fieldId)){
      return this.errorsHash.fields[fieldId].errorsCounter;
    }
    return null;
  }

  getAmountErrorsInErrorsHash(){
    return this.errorsHash.errorsCounter;
  }

  toggleErrorMsg(field, msg, show_error, error_type){
    var error_id = "error-" + error_type + "--" + field.getAttribute('id') ;
    var error_elem = $("#" + error_id);

    if(!error_elem.length && show_error){
      $("<div class='error highlight__text' id=\'"   + error_id   +"'\'>"   +msg   +"</div>").insertAfter(field);
    }else if (error_elem.length && !show_error){
      error_elem.remove();
    }
    return show_error;
  }

  isHighlightedWithError(field){
    let highlighted = field.classList.contains('highlight__input');
    return highlighted;
  }

  highlightFieldWithError(field, shouldHighlight=true){
    if(shouldHighlight){
      field.classList.add("highlight__input");
    }else{
      field.classList.remove("highlight__input");
    }
  }

  validateFieldIsNotBlank(field){
    return field.disabled || !this.isFieldEmpty(field);
  }

  isFieldEmpty(field){
    if(field.value.trim()) return false;

    return true;
  }

  validateConfirmationFieldIsEqual(field){
    let otherFieldDataId = field.dataset.formValidationsConfirmInputFor;
    let otherFieldSelector = '*[data-form-validations-confirm-input-id=' + otherFieldDataId +']';
    let otherField = this.formTarget.querySelectorAll(otherFieldSelector)[0];
    let isFieldValid = field.value == otherField.value;
    return isFieldValid;
  }

  validatePasswordFielIstStrongEnough(field, passwordStrengthTreshold=2){
    if(!field.hasAttribute('required') && this.isFieldEmpty(field)) return true;

    let isFieldValid = this.getPasswordScore(field.value) >= passwordStrengthTreshold;
    return isFieldValid;
  }

  validateCheckboxFieldIsChecked(field){
    return field.checked;
  }

  getPasswordScore(password){
    return zxcvbn(password).score;
  }
}

