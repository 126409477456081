import { Controller } from "stimulus"

export default class extends Controller {

  closeAndroid() {
    Modal.close()
  }

  closeiOS() {
    window.webkit.messageHandlers.nativeApp.postMessage({
      name: "Close Modal",
      action: "closeModal"
    })
  }
}
