import { Controller } from "stimulus"

export default class extends Controller {
  checkLink(e){
    let targetElement = e.currentTarget;
    let link = '/alert_redirections/new?sub_category=reconnect_plaid';
    let targetElementPath = targetElement.pathname+targetElement.search

    if (targetElementPath === link)
      e.preventDefault();
  }
}