import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    this.bertha_modal = $("#js-bertha-modal");
    this.bertha_card = $("#js-bertha-card");
    this.display = false;
    this.mobile_pixel_breakpoint = 767;

  }
  connect() {
    // Carousel at registration view
    this.update_elems_function_wrapper =  (e) => {this.update_elements_visibility();}
    $(window).on("resize",this.update_elems_function_wrapper);

    $(".js-bertha-actionable").on("click", () => {
      this.display = true;
      this.update_elements_visibility();
      this.send_amp_event_clicked_covid_support_btn();
    });

    $(".js-bertha-close").on("click", () => {
      this.display = false;
      this.update_elements_visibility();
    });
  }

  disconnect() {
    $(window).off("resize", this.update_elems_function_wrapper);
  }

  update_elements_visibility(){
    if ( this.is_mobile_size($(window).width())) {
      this.toggle_mobile_elements(this.display);
      this.toggle_desktop_elements(false);
    } else {
      this.toggle_mobile_elements(false);
      this.toggle_desktop_elements(this.display);
    }
  }

  toggle_mobile_elements(should_display_elements = true){
    if(should_display_elements){
      this.bertha_modal.modal("show");
      this.bertha_modal.show();
    }else{
      this.bertha_modal.modal("hide");
      this.bertha_modal.hide();
    }
  }

  toggle_desktop_elements(should_display_elements = true){
    if(should_display_elements){
      this.bertha_card.show();
    }else{
      this.bertha_card.hide();
    }
  }

  is_mobile_size(size){
    return size < this.mobile_pixel_breakpoint;
  }

  send_amp_event_clicked_covid_support_btn(){
    amplitude.getInstance().logEvent("Clicked Covid-19 support button", {
      url: document.location.href,
      page_name: document.title,
    });
  }
}
