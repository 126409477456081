import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.nav_content = $(".js-main-side-nav__content");
    this.nav_bg = $(".js-main-side-nav__bg");
    this.nav = $(".js-main-side-nav");
    this.body = $(".js-body");
    this.clicked_search = false;

    this.toggle_freeze_scroll = (freeze, element) => {
      if (freeze) {
        element.css({ "max-height": "100vh", overflow: "hidden" });
      } else {
        element.css({ "max-height": "", overflow: "" });
      }
    };

    this.collapse_main_side_nav = (nav, nav_bg, nav_content, body) => {
      nav.addClass("visibility-hidden");
      nav_bg.removeClass("anim-visible").addClass("anim-hidden");
      nav_content.removeClass("anim-appear-from-left");
      this.toggle_freeze_scroll(false, body);
    };
  }

  expandSideNav() {
    this.nav.removeClass("visibility-hidden");
    this.nav_bg.removeClass("anim-hidden").addClass("anim-visible");
    this.nav_content
      .removeClass("anim-disappear-to-right")
      .addClass("anim-appear-from-left");
    this.toggle_freeze_scroll(true, this.body);
  }

  collapseSideNav() {
    this.collapse_main_side_nav(
      this.nav,
      this.nav_bg,
      this.nav_content,
      this.body
    );
    this.nav_content.addClass("anim-disappear-to-right");
    setTimeout(() => {
      this.nav_content.removeClass("anim-disappear-to-right");
    }, 400);
  }

  toggleSearch() {
    $("#search-bar-header").toggle();
    $("#search-blog-posts-input").focus();
  }

  viewPoints() {
    $(".js_view_points_funifier").trigger("click");
  }

  connect(){  
    $("#search-blog-posts-input").on('keyup', (e) => {
      if (e.keyCode === 13) {
        amplitude.getInstance().logEvent('Search term submitted',
        {
          'search_text':$(this)[0].value,
          'url':document.location.href,
          'page_name':document.title
        });
        this.clicked_search = false;
      }
    });

    $("#search-blog-posts-input").on("focus", () => {
      this.clicked_search = true;
    });
  }

  disconnect() {
    this.collapse_main_side_nav(
      this.nav,
      this.nav_bg,
      this.nav_content,
      this.body
    );
    $("#search-bar-header").hide();

    if (this.clicked_search) {
      let search_length = 0;
      let search_input = $("#search-blog-posts-input").val().length;

      if (search_input > 0 && search_input <= 10) {
        search_length = 10;
      }else if (search_input > 10) {
        search_length = 20;
      }
      amplitude.getInstance().logEvent('Typed search content',
      {
        'search_length': search_length,
        'url': document.location.href,
        'page_name': document.title
      });
    }
  }
}
