import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    createActionLogPath: String
  }

  triggerAction(event) {
    const data = event.currentTarget.dataset

    const action_name = data.gamificationActionName
    const action_properties = JSON.parse(data.gamificationActionProperties)

    $.ajax({
      method: 'POST',
      url: this.createActionLogPathValue,
      data: {
        action_log: {
          action: action_name,
          properties: action_properties
        }
      }
    });
  }
}
