import { Controller } from 'stimulus'

export default class extends Controller {
  clickedActivity(event) {
    $.ajax({
      method: 'POST',
      url: "/funifier/action_logs",
      data: {
        action_log: {
          action: "Clicked to open link",
          attributes: {
            href: event.currentTarget.href
          }
        } 
      }
    });
  }
}
