import { Controller } from "stimulus"

export default class extends Controller {

  initialize(){
    //Variables declaration
    this.month_carousels = $(".month__carousel");
    this.carrousel_contents = $(".month__carousel__content");
    this.carrousel_content_items = new Array(this.carrousel_contents.length);
    this.indexChallenges =  new Array(this.month_carousels.length);
    this.challengeNames = new Array(this.month_carousels.length);

    //Hide all the elements passed except the index passed
    this.hide_all_except_index = (elems, index_to_show) => {
      $.each(elems, function(index){
        if(index == index_to_show){
          $(this).show();
        }else{
          $(this).hide();
        }
      });
    }

    this.set_up_mont_carrousel = () => {
      $('.month__carousel').each((index, element)=>{
        $(element).not('.slick-initialized').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          prevArrow: '<button id="btn-slick-prev-challenge_' + "index'" + ' type="button" class="slick-prev"></button',
          nextArrow: '<button id="btn-slick-after-challenge_' + "index'" + ' type="button" class="slick-next"></button',
          initialSlide: this.indexChallenges[index],
          waitForAnimate: false,
        });
      });

      //This will not work when there are more than one child carousels
      $('.slick-prev').each((index, element)=>{
        $(element).click(()=>{
          this.indexChallenges[index] -= 1;
          if(this.indexChallenges[index] < 0){
            this.indexChallenges[index] = this.carrousel_content_items[index].length - 1;
          }
          this.hide_all_except_index(this.carrousel_content_items[index], this.indexChallenges[index]);
          amplitude.getInstance().logEvent('Clicked tile date scroll',
          {
            'url':document.location.href,
            'challenge_name':this.challengeNames[index],
            'scroll_direction':'previous'
          });
        });
      });

      $('.slick-next').each((index, element)=>{
        $(element).on("click", ()=>{
          this.indexChallenges[index] += 1;
          if (this.indexChallenges[index] >  this.carrousel_content_items[index].length - 1){
            this.indexChallenges[index] = 0;
          }
          this.hide_all_except_index(this.carrousel_content_items[index], this.indexChallenges[index]);
          amplitude.getInstance().logEvent('Clicked tile date scroll',
          {
            'url':document.location.href,
            'challenge_name':this.challengeNames[index],
            'scroll_direction':'next'
          });
        });
      });
    }
  }

  connect(){
    //Obtain carrousel_content_items for each carrousel_contents
    this.carrousel_contents.each((index, element)=>{
      this.carrousel_content_items[index] = $(element).find(".month__carousel__content__item");
    });

    //Obtain challenge_name for each carrousel
    this.month_carousels.each((index, element)=>{
      this.challengeNames[index] = $(element).data('challenge')
    })

    //Obtain each month index for each carrousel
    $(".actual_month_index").each((index, element)=>{
      this.indexChallenges[index] = Number($(element).attr("val"));
    });

    //Hide all carrousel_content_items for all carrousel_contents
    $.each(this.carrousel_content_items, (index, element)=>{
      this.hide_all_except_index($(element), this.indexChallenges[index]);
    });

    this.set_up_mont_carrousel();
    $(".month__carousel__header").removeClass("transparent");

    $(window).on('resize', this.set_up_mont_carrousel)
  }

  disconnect(){
    $(window).off('resize', this.set_up_mont_carrousel)
  }
}