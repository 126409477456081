import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.info = JSON.parse(this.data.get("data"))
    this.current_user = this.info.user
    this.topic = this.info.topic
    this.messageboard = this.info.messageboard
    this.gamification_action_logs_path = this.info.gamification_action_logs_path
    this.post_length = 0;
    this.search_length = 0;
    this.topic_length = 0

    this.log_gamification_post = () => {
      let href = document.location.href.split("/");
      let board = href[href.length - 2];
      $.ajax({
        method: 'POST',
        url: this.gamification_action_logs_path,
        data: {
          action_log: {
            action: 'Posted to the community forum',
            properties: {
              category: board
            }
          }
        }
      });
    }

    this.log_gamification_topic = () => {
      let href = document.location.href.split("/");
      let board = href[href.length - 1];
      $.ajax({
        method: 'POST',
        url: this.gamification_action_logs_path,
        data: {
          action_log: {
            action: 'Posted to the community forum',
            properties: {
              category: board
            }
          }
        }
      });
    }
  }

  connect() {
    $(".thredded--messageboard").on("click", function(event) {
      var href = $(event.currentTarget).attr("href").split("/");
      var board = href[href.length - 1];
      amplitude.getInstance().logEvent('Clicked message board link',
      {
        'board_type':board,
        'url':document.location.href,
        'page_name':document.title
      });
    });

    $(".thredded--topics--title").on("click", function(event) {
      if(document.location.href.includes("&q=")) {
        amplitude.getInstance().logEvent('Clicked Forum search result',
        {
          'topic_text':$(event.currentTarget)[0].innerText,
          'url':document.location.href,
          'page_name':document.title
        });
      } else {
        amplitude.getInstance().logEvent('Clicked topic link',
        {
          'topic_text':$(event.currentTarget)[0].innerText,
          'url':document.location.href,
          'page_name':document.title
        });
      }
    });

    $("#q").on("change paste keyup", function() {
      this.search_length = $('#q').val().length;
    }.bind(this));

    $("#post_content").on("change paste keyup", function(){
      this.post_length = $("#post_content").val().length;
    }.bind(this));

    $(".thredded--form-list.on-top").on("change paste keyup", function(){
      this.topic_length = $('#topic_title').val().length + $('#topic_content').val().length;
    }.bind(this));

    $(".thredded--main-section.thredded--topics").submit(function() {
      amplitude.getInstance().logEvent('Submitted topic',
      {
        'topic_text': $('#topic_title')[0].value,
        'url': document.location.href,
        'page_name': document.title,
        'board_type': this.messageboard && this.messageboard.name
      });

      if(this.current_user)
        this.log_gamification_topic();
    }.bind(this));

    $(".thredded--form.thredded--post-form").submit(function() {
      amplitude.getInstance().logEvent('Submitted post',
      {
        'post_length': this.post_length,
        'url': document.location.href,
        'page_name': document.title,
        'topic_text': this.topic && this.topic.title,
        'board_type': this.messageboard && this.messageboard.name
      });

      if(this.current_user)
        this.log_gamification_post();
    }.bind(this));

    $(".thredded--post--dropdown--toggle").mouseover(function(){
      amplitude.getInstance().logEvent('Hovered post settings',
      {
        'url':document.location.href,
        'page_name':document.title
      });
    });

    $(".thredded--post--dropdown--actions--item").on("click", function(event) {
      if($(event.currentTarget)[0].innerText) {
        var action = $(event.currentTarget)[0].innerText;
      }else if($(event.currentTarget)[0].value) {
        var action = $(event.currentTarget)[0].value;
      }
      amplitude.getInstance().logEvent('Selected post setting',
      {
        'setting_type':action,
        'url':document.location.href,
        'page_name':document.title
      });
    });

    $('form.custom__thredded__navigation__search-topics').submit(function(event) {
      amplitude.getInstance().logEvent('Submitted Forum search',
      {
        'search_text': $(event.currentTarget)[0][1].value,
        'url':document.location.href,
        'page_name':document.title
      });
    });

    if(document.location.href.match(/.*\/forum$/)) {
      amplitude.getInstance().logEvent('Landed on forums',
      {
        'url':document.location.href,
        'page_name':document.title
      });
    } else if(document.location.href.match(/.*\/forum\/[^/]+$/)) {
      amplitude.getInstance().logEvent('Landed on messageboard',
      {
        'board_type': this.messageboard && this.messageboard.name,
        'url':document.location.href,
        'page_name':document.title
      });
    }
  }

  disconnect(){
    if (this.post_length > 0) {
      amplitude.getInstance().logEvent('Typed post content',
      {
        'post_length': this.post_length,
        'url': document.location.href,
        'page_name': document.title,
        'topic_text': this.topic && this.topic.title,
        'board_type': this.messageboard && this.messageboard.name
      });
      this.post_length = 0;
    }
    if (this.topic_length > 0) {
      amplitude.getInstance().logEvent('Typed topic content',
      {
        'topic_text': $('#topic_title').val() + ' : ' + $('#topic_content').val(),
        'topic_length': this.topic_length,
        'url': document.location.href,
        'page_name': document.title,
        'board_type': this.messageboard && this.messageboard.name
      });
    }
    if(this.search_length > 0) {
      amplitude.getInstance().logEvent('Typed Forum search content',
      {
        'search_length': this.search_length,
        'url': document.location.href,
        'page_name': document.title
      });
    }
  }
}
