import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ["variant", "panel", "secondaryPanel"]
  }

  connect() {
    this.activeVariantClasses = (this.data.get('activeVariant') || 'active').split(' ')
    this.inactiveVariantClasses = (this.data.get('inactiveVariant') || 'inactive').split(' ')
    if (this.anchor) this.index = this.variantTargets.findIndex((variant) => variant.id === this.anchor)
    this.showVariant()
    this.createUtmCampaign()
    this.wellsFargoIndex = 1
  }

  change(event) {
    event.preventDefault()
    // If target specifies a function and there is an utm_campaign param, use that
    if(this.isUtmCampaign(event)) {
      this.index = this.wellsFargoIndex
      this.triggerChange()
      return
    }
    // If target specifies an index, use that
    if (event.currentTarget.dataset.index) {
      this.index = event.currentTarget.dataset.index

    // If target specifies an index, use that
    } else if (event.currentTarget.dataset.id) {
      this.index = this.variantTargets.findIndex((variant) => variant.id == event.currentTarget.dataset.id)

    // Otherwise, use the index of the current target
    } else {
      this.index = this.variantTargets.indexOf(event.currentTarget)
    }
    this.triggerChange()
  }

  showVariant() {
    this.panelTargets.forEach((panel, index) => {
      this.togglePanel(panel, index)
    })
  }

  togglePanel(panel, index){
    if (index === this.index) {
      panel.classList.remove('display__none')
    } else {
      panel.classList.add('display__none')
    }
  }

  triggerChange(){
    window.dispatchEvent(new CustomEvent('tsc:variant-change'))
  }

  createUtmCampaign() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const utm_campaign = urlParams.get('utm_campaign')
    if(utm_campaign) {
      localStorage.setItem('utm_campaign', utm_campaign);
      this.utmCampaign =  localStorage.getItem('utm_campaign');
    }
  }

  isUtmCampaign(event) {
    return event.currentTarget.dataset.function == 'return' && this.utmCampaign
  }

  get index() {
    return parseInt(this.data.get('index') || 0)
  }

  set index(value) {
    this.data.set('index', (value >= 0 ? value : 0))
    this.showVariant()
  }

  get anchor() {
    return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
  }
}
