import { Controller } from "stimulus"
export default class extends Controller {
  initialize() {
    const current_path = window.location.pathname.replace("/", "");
    if (this.currentPlatform() == ' web') {
        this.disconnect()
      }
    this.logEvent(current_path);
  }


  logEvent(name, params) {
    if (!name) {
      return;
    }

    switch(this.currentPlatform()){
      case 'android':
        window.AnalyticsWebInterface.logEvent(name, JSON.stringify(params));
        break
      case 'ios':
        let message = {
          command: 'logEvent',
          name: name,
          parameters: params
        };
        window.webkit.messageHandlers.firebase.postMessage(message);
        break
    }
  }

  currentPlatform() {
    if (window.AnalyticsWebInterface) return 'android'
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.firebase) return 'ios'
    return 'web'
  }
}
