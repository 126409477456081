import { Controller } from "stimulus"

export default class extends Controller {
  static get targets(){
    return ["form","confirmInput","invitationInput", "submit"]
  }
  static values = {
    externalPartnerName: String,
    isInvalidInvitation: Boolean,
    isUserLoggedIn: Boolean
  }

  initialize(){
    this.fieldsCounter = 0;
    this.errorsHash = {fields:{}, errorsCounter: 0};
    this.areDynamicValidationsRunning = false;
    this.formTarget.noValidate = true;
  }

  connect(){
    let requiredFieldSelectors = 'textarea:required, input:required';
    this.requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);
    this.submitTarget.setAttribute('disabled','disabled');

    // [Target/Special Campaign] Forced Input pattern script
    if(!this.isUserLoggedInValue) this.applyForcedPattern(this.requiredFields);

    this.validateForm((e)=>{
      this.validateFieldWith(this.invitationInputTarget, `${this.externalPartnerNameValue} cannot be blank`, 'empty-text', this.isFieldNotEmpty.bind(this), false);
    });

    for(let rField of this.requiredFields){
      rField.addEventListener('keyup', (e) =>{
        this.validateForm((e2)=>{
          this.validateFieldWith(e.currentTarget, `${this.externalPartnerNameValue} cannot be blank`, 'empty-text', this.isFieldNotEmpty.bind(this), false);
        });
      });
    }

    this.formTarget.addEventListener("submit", (e)=>{
      this.startDynamicValidations(e);
    });

    // Note: Toggle error on load if the sent id was not found.
    this.showInvitationError(this.isInvalidInvitationValue);
  }

  startDynamicValidations(submit) {
    this.requiredFields.forEach(input => {

      this.validateFieldWith(input, `${this.externalPartnerNameValue} cannot be blank`, 'empty-text', this.isFieldNotEmpty.bind(this));
      this.validateFieldWith(input, `${this.externalPartnerNameValue} is invalid`, 'inv-pattern', this.hasValidPattern.bind(this));

      // Passing submit event to call preventDefault if fields don't match
      if(this.confirmInputTarget) this.validateFieldWith(this.confirmInputTarget,`${this.externalPartnerNameValue}s do not match`, 'confirm-input', this.validateConfirmationFieldIsEqual.bind(this), true, submit);

      input.addEventListener('keyup', (e) =>{
        this.validateForm((e2)=>{
          this.validateFieldWith(e.currentTarget, `${this.externalPartnerNameValue} cannot be blank`, 'empty-text', this.isFieldNotEmpty.bind(this));
        });
      });

      input.addEventListener('keyup', (e)=>{
        this.validateForm( (e2)=>{
          this.validateFieldWith(e.currentTarget, `${this.externalPartnerNameValue} is invalid`, 'inv-pattern', this.hasValidPattern.bind(this));
        });
      });

      if(this.confirmInputTarget){
        input.addEventListener('keyup', (e)=>{
          this.validateForm( (e2)=>{
            this.validateFieldWith(this.confirmInputTarget,`${this.externalPartnerNameValue}s do not match`, 'confirm-input', this.validateConfirmationFieldIsEqual.bind(this));
          });
        });
      }

    });

    if (!this.formTarget.checkValidity()) {
      submit.preventDefault();
      submit.stopImmediatePropagation();
    }
  };

  validateFieldWith(field, msg, validationType, isFieldValidCallback, showErrorOnInvalid=true, submit=null){
    let fieldId = this.setDataIdToField(field);
    let isFieldValid = isFieldValidCallback(field);

    if(isFieldValid){
      this.removeErrorOfField(fieldId, validationType);
      this.toggleErrorMsg(field, msg, false, validationType);

      if(this.getAmountErrorsInField(fieldId) === 0) this.highlightFieldWithError(field,false);

    } else {
      this.addErrorOfField(fieldId, validationType);

      if(showErrorOnInvalid){
        this.highlightFieldWithError(field);
        this.toggleErrorMsg(field, msg, true, validationType);
      }
    }

    if(submit && !isFieldValid) {
      submit.preventDefault();
      submit.stopImmediatePropagation();
    }
  }

  validateForm(validationsCallback){
    validationsCallback();
    if (this.getAmountErrorsInErrorsHash() === 0){
      this.submitTarget.removeAttribute('disabled');
      return true;
    }

    this.submitTarget.setAttribute('disabled','disabled');
    return false;
  }

  validateConfirmationFieldIsEqual(){
    return this.invitationInputTarget.value == this.confirmInputTarget.value ? true : false
  }

  isFieldNotEmpty(field){
    return field.disabled || !field.validity.valueMissing
  }

  hasValidPattern(field){
    return !field.validity.patternMismatch
  }

  getErrorMsg(msg, error_id){
    let error_msg = document.createElement('div');
    error_msg.classList.add('error', 'highlight__text');
    error_msg.id = error_id;
    error_msg.innerText = msg;
    return error_msg;
  }

  toggleErrorMsg(field, msg, show_error, error_type){
    const error_id = "error-" + error_type + "--" + field.getAttribute('id');
    const error_elem = document.getElementById(error_id) || '';

    if(!error_elem && show_error){
      this.error_msg = this.getErrorMsg(msg, error_id)
      this.insertAfter(this.error_msg, field)
    } else if (error_elem && !show_error){
      error_elem.remove();
    }
    return show_error;
  }

  highlightFieldWithError(field, shouldHighlight=true){
    if(shouldHighlight){
      field.classList.add("highlight__input");
    }else{
      field.classList.remove("highlight__input");
    }
  }

  setDataIdToField(field){
    let fieldDataId = field.dataset.formValidationsFieldId;
    if(!fieldDataId){
      field.dataset.formValidationsFieldId = this.fieldsCounter;
      return this.fieldsCounter++;
    }
    return fieldDataId;
  }

  getDataIdOfField(field){
    return field.dataset.formValidationsFieldId;
  }


  addErrorOfField(fieldId, errorType){
    if(!this.errorsHash.fields.hasOwnProperty(fieldId)) {
      this.errorsHash.fields[fieldId] = {errors:new Set(), errorsCounter: 0};
    }

    let fieldErrorsObj = this.errorsHash.fields[fieldId];
    if(!fieldErrorsObj.errors.has(errorType)){
      fieldErrorsObj.errors.add(errorType);
      fieldErrorsObj.errorsCounter++;
      this.errorsHash.errorsCounter++;
    }
  }

  removeErrorOfField(fieldId, errorType){
    if(this.errorsHash.fields.hasOwnProperty(fieldId)){

      let fieldErrorsObj = this.errorsHash.fields[fieldId];
      if(fieldErrorsObj.errors.has(errorType)){
        fieldErrorsObj.errors.delete(errorType);
        fieldErrorsObj.errorsCounter--;
        this.errorsHash.errorsCounter--;
      }
    }
  }

  getAmountErrorsInField(fieldId, errorType){
    if(this.errorsHash.fields.hasOwnProperty(fieldId)){
      return this.errorsHash.fields[fieldId].errorsCounter;
    }
    return null;
  }

  getAmountErrorsInErrorsHash(){
    return this.errorsHash.errorsCounter;
  }

  showInvitationError(isInvalid = false){
    if(isInvalid) {
      this.highlightFieldWithError(this.invitationInputTarget);
      this.toggleErrorMsg(this.invitationInputTarget, `${this.externalPartnerNameValue} is invalid`, true, 'inv-pattern');
    }
  }

  applyForcedPattern(fields) {
    for (let field of fields) {
      // On 'key up' check number length and update the field accordingly
      field.addEventListener("keyup", function() {
          let fieldValue = this.value.replace(/^0+/, '');
          let fieldValueTrimmed = fieldValue.replace(/^0+/, '');
          let preventOverwrite = false;
          if(fieldValueTrimmed.length <= 7) {
            preventOverwrite = fieldValue.startsWith("000") ? true : false;
            fieldValue = '000'  + fieldValue;
          } else {
            preventOverwrite = fieldValue.startsWith("00") ? true : false;
            fieldValue = '00'  + fieldValue;
          }
          if(!preventOverwrite) this.value = fieldValue
      });
    }
  }

  insertAfter(newNode, existingNode) {
    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
  }

}